<template>
  <v-avatar class="elevation-6 profile" :size="size" :color="getColor">
    <v-img
      :src="selectedFile ? selectedImage : img"
      alt="agency logo"
      v-if="selectedFile || img"
    />
    <span
      class="white--text noselect"
      :style="'font-size:' + size / 2 + 'px;'"
      v-else-if="name"
      >{{ name[0].toUpperCase() }}</span
    >
    <v-btn
      color="primary"
      class="edit_button"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onEditButtonClick"
      v-if="editable"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </v-avatar>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 100
    },
    img: {
      type: String
    },
    onImageSelected: {
      type: Function
    },
    name: {
      type: String
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colors: [
        "#D2691E",
        "#DEB887",
        "#5F9EA0",
        "#B8860B",
        "#A9A9A9",
        "#A52A2A",
        "#DAA520",
        "#FF69B4",
        "#FFFFF0",
        "#4B0082",
        "#F0E68C",
        "#32CD32",
        "#3CB371",
        "#FFDEAD",
        "#FFA500",
        "#CD853F",
        "#800080",
        "#BC8F8F",
        "#A0522D",
        "#008080",
        "#FF6347",
        "#EE82EE",
        "#F5DEB3",
        "#00FF7F",
        "#FFFF00",
        "#9ACD32"
      ],
      selectedFile: null,
      placeholderImage: require("@/assets/default.jpg"),
      isSelectingPhoto: false,
      selectedImage: null
    };
  },
  methods: {
    onEditButtonClick() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.onImageSelected(this.selectedFile);
      }
    }
  },
  computed: {
    getColor() {
      if (this.selectedFile || this.img) return "transparent";
      if (!this.name) return "orange";
      const letterIndex = this.name[0].toUpperCase().charCodeAt() - 65;
      return this.colors[letterIndex];
    }
  }
};
</script>
<style scoped>
.profile {
  overflow: visible;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>
