import { render, staticRenderFns } from "./DonationDetails.vue?vue&type=template&id=a6046bba&scoped=true"
import script from "./DonationDetails.vue?vue&type=script&lang=js"
export * from "./DonationDetails.vue?vue&type=script&lang=js"
import style0 from "./DonationDetails.vue?vue&type=style&index=0&id=a6046bba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6046bba",
  null
  
)

export default component.exports